<template>
  <div>
    <!-- Modal de Autenticação -->
    <div v-if="showPasswordModal" class="modal">
      <div class="modal-content">
        <button class="close-button" @click="closePasswordModal">&times;</button>
        <h2 class="modal-title">Autenticação Necessária</h2>
        <form @submit.prevent="checkPassword" class="modal-form">
          <div class="form-group">
            <label for="password" class="form-label">Digite a senha para acessar a tela:</label>
            <input
              type="password"
              id="password"
              v-model="inputPassword"
              placeholder="Senha"
              class="form-input"
              required
            />
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-primary">Entrar</button>
            <button type="button" @click="cancelAuthentication" class="btn btn-secondary">Cancelar</button>
          </div>
          <p v-if="passwordError" class="error-message">Senha incorreta, tente novamente.</p>
        </form>
      </div>
    </div>

    <!-- Conteúdo Principal -->
    <div v-if="!showPasswordModal">
      <!-- Navegação por Abas -->
      <div class="tabs">
        <button
          :class="['tab', { active: activeTab === 'tributacao' }]"
          @click="activeTab = 'tributacao'"
        >
          Tributação
        </button>
        <button
          :class="['tab', { active: activeTab === 'gerenciamento' }]"
          @click="activeTab = 'gerenciamento'"
        >
          Gerenciamento de Classes
        </button>
      </div>

      <!-- Conteúdo das Abas -->
      <div v-if="activeTab === 'tributacao'">
        <!-- Seleção de Transportador -->
        <div class="header">
          <label for="transportador-select" class="select-label">Escolha o Transportador:</label>
          <div class="custom-select-container">
            <select
              id="transportador-select"
              v-model="selectedTransportador"
              @change="onSelectTransportador"
              class="custom-select"
            >
              <option value="" disabled>Selecione um transportador</option>
              <option
                v-for="transportador in transportadores"
                :key="transportador.id"
                :value="transportador"
              >
                {{ transportador.nomeFantasia }}
              </option>
            </select>
            <span class="select-arrow">&#9662;</span>
          </div>
        </div>

        <!-- Informações do Transportador Selecionado -->
        <div class="transportador-card" v-if="selectedTransportador">
          <div class="transportador-info">
            <p><strong>CNPJ:</strong> {{ selectedTransportador.cpfCnpj }}</p>
            <p><strong>Descrição:</strong> {{ selectedTransportador.razaoSocial }}</p>
            <p><strong>Regime:</strong> {{ selectedTransportador.descricaoRegime }}</p>
            <p><strong>Classe:</strong> {{ selectedTransportador.idClasse }}</p>
            <p><strong>UF:</strong> {{ selectedTransportador.endereco.uf }}</p>
          </div>
        </div>

        <!-- Seleção de Tributação -->
        <div class="header">
          <label for="tributacao-select" class="select-label">Escolha a Tributação:</label>
          <div class="custom-select-container">
            <select id="tributacao-select" v-model="selectedTributacao" class="custom-select">
              <option value="cfop">CFOP</option>
              <option value="cst">CST</option>
              <option value="aliquota">Alíquota</option>
              <option value="reducao">Redução</option>
            </select>
            <span class="select-arrow">&#9662;</span>
          </div>
        </div>

        <!-- Tabela de Tributação -->
        <div class="table-container">
          <table class="tributacao-table">
            <thead>
              <tr>
                <th class="flag-cell">
                  <img src="../assets/brasil.png" alt="Bandeira do Brasil" class="flag-image" />
                </th>
                <th
                  v-for="(estado, colIndex) in estados"
                  :key="estado"
                  :class="{ highlightBorder: colIndex === hoverColIndex }"
                >
                  {{ estado }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(estadoOrigem, rowIndex) in estados" :key="estadoOrigem">
                <td :class="['state-column', { highlightBorder: rowIndex === hoverRowIndex }]">
                  {{ estadoOrigem }}
                </td>
                <td
                  v-for="(estadoDestino, colIndex) in estados"
                  :key="estadoDestino"
                  @mouseover="setHoverIndices(rowIndex, colIndex)"
                  @mouseleave="resetHoverIndices"
                  @click="openModal(estadoOrigem, estadoDestino)"
                  :class="['clickable-cell', { 'pair-cell': estadoOrigem === estadoDestino }]"
                >
                  {{ getValue(estadoOrigem, estadoDestino) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-else-if="activeTab === 'gerenciamento'">
        <!-- Gerenciamento de Classes -->
        <div class="gerenciamento-container">
          <div class="header">
            <button @click="openAddClassModal" class="add-button">Adicionar Nova Classe</button>
          </div>

          <!-- Lista de Classes -->
          <div class="classes-list">
            <table class="classes-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Descrição</th>
                  <th>Ativo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="classe in classes" :key="classe.id">
                  <td>{{ classe.id }}</td>
                  <td>{{ classe.descricao }}</td>
                  <td>
                    <input
                      type="checkbox"
                      v-model="classe.ativo"
                      @change="toggleAtivo(classe)"
                    />
                  </td>
                  <td>
                    <button @click="openEditClassModal(classe)" class="action-button edit-button">Editar</button>
                    <button @click="deleteClass(classe)" class="action-button delete-button">Excluir</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Modal de Edição de Tributação -->
      <div v-if="showModal" class="modal">
        <div class="modal-content">
          <button class="close-button" @click="closeModal">&times;</button>
          <h2 class="modal-title">Editar Detalhes da Tributação</h2>
          <form @submit.prevent="saveModalData" class="modal-form">
            <div class="form-group">
              <label class="form-label">Origem:</label>
              <input type="text" :value="modalData.ufRemetente" readonly class="form-input" />
            </div>
            <div class="form-group">
              <label class="form-label">Destino:</label>
              <input type="text" :value="modalData.ufDestinatario" readonly class="form-input" />
            </div>
            <div class="form-group">
              <label for="cfop" class="form-label">CFOP:</label>
              <input type="text" id="cfop" v-model="modalData.cfop" class="form-input" />
            </div>
            <div class="form-group">
              <label for="cst" class="form-label">CST:</label>
              <input type="text" id="cst" v-model="modalData.cst" class="form-input" />
            </div>
            <div class="form-group">
              <label for="indSn" class="form-label">Indicador Simples Nacional:</label>
              <input type="text" id="indSn" v-model="modalData.indSn" class="form-input" />
            </div>
            <div class="form-group">
              <label for="regime" class="form-label">Regime:</label>
              <input type="number" id="regime" v-model.number="modalData.regime" step="1" class="form-input" />
            </div>
            <div class="form-group">
              <label for="msgFiscal" class="form-label">Mensagem Fiscal:</label>
              <textarea id="msgFiscal" v-model="modalData.msgFiscal" class="form-input" placeholder="Digite a mensagem fiscal"></textarea>
            </div>
            <div class="form-group">
              <label for="aliquota" class="form-label">Alíquota:</label>
              <input type="number" id="aliquota" v-model.number="modalData.aliquota" step="0.01" class="form-input" />
            </div>
            <div class="form-group">
              <label for="reducao" class="form-label">Redução:</label>
              <input type="number" id="reducao" v-model.number="modalData.reducao" step="0.01" class="form-input" />
            </div>
            <div class="form-actions">
              <button type="submit" class="btn btn-primary">Salvar</button>
              <button type="button" @click="closeModal" class="btn btn-secondary">Cancelar</button>
            </div>
          </form>
        </div>
      </div>

      <!-- Modal para Adicionar Nova Classe -->
      <div v-if="showAddClassModal" class="modal">
        <div class="modal-content">
          <button class="close-button" @click="closeAddClassModal">&times;</button>
          <h2 class="modal-title">Adicionar Nova Classe</h2>
          <form @submit.prevent="addClass" class="modal-form">
            <div class="form-group">
              <label for="descricao" class="form-label">Descrição:</label>
              <input
                type="text"
                id="descricao"
                v-model="newClassDescription"
                placeholder="Digite a descrição da classe"
                class="form-input"
                required
              />
            </div>
            <div class="form-actions">
              <button type="submit" class="btn btn-primary">Salvar</button>
              <button type="button" @click="closeAddClassModal" class="btn btn-secondary">Cancelar</button>
            </div>
          </form>
        </div>
      </div>

      <!-- Modal para Editar Classe -->
      <div v-if="showEditClassModal" class="modal">
        <div class="modal-content">
          <button class="close-button" @click="closeEditClassModal">&times;</button>
          <h2 class="modal-title">Editar Classe</h2>
          <form @submit.prevent="updateClass" class="modal-form">
            <div class="form-group">
              <label for="edit-descricao" class="form-label">Descrição:</label>
              <input
                type="text"
                id="edit-descricao"
                v-model="editClassData.descricao"
                placeholder="Digite a descrição da classe"
                class="form-input"
                required
              />
            </div>
            <div class="form-group">
              <label for="edit-ativo" class="form-label">Ativo:</label>
              <input
                type="checkbox"
                id="edit-ativo"
                v-model="editClassData.ativo"
                class="form-input-checkbox"
              />
            </div>
            <div class="form-actions">
              <button type="submit" class="btn btn-primary">Salvar</button>
              <button type="button" @click="closeEditClassModal" class="btn btn-secondary">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      activeTab: "tributacao", // 'tributacao' ou 'gerenciamento'
      selectedTributacao: "aliquota",
      transportadores: [],
      selectedTransportador: null,
      estados: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
      tributacoes: {
        cfop: {},
        cst: {},
        msgFiscal: {},
        aliquota: {},
        reducao: {},
        completeData: {},
      },
      showModal: false,
      showPasswordModal: true,
      inputPassword: "",
      passwordError: false,
      modalData: {},
      hoverRowIndex: null,
      hoverColIndex: null,

      // Gerenciamento de Classes
      classes: [],
      showAddClassModal: false,
      showEditClassModal: false,
      newClassDescription: "",
      editClassData: {},
    };
  },
  methods: {
    // Método para verificar a senha
    checkPassword() {
      if (this.inputPassword === "elvisfeliz") {
        this.showPasswordModal = false;
        this.passwordError = false;
      } else {
        this.passwordError = true;
      }
    },
    // Método para fechar a modal de autenticação
    closePasswordModal() {
      this.showPasswordModal = false;
      this.passwordError = false;
    },
    // Método para cancelar a autenticação
    cancelAuthentication() {
      this.$router.push("/");
    },
    // Método para buscar transportadores
    async buscarTransportadores() {
      try {
        const idUsuario = 687;
        const response = await axios.get(
          `https://api.cadastro.workinnovation.com.br/v1/cadastro/participante/busca-usuario?idUsuario=${idUsuario}`
        );
        this.transportadores = response.data;
      } catch (error) {
        console.error("Erro ao buscar transportadores:", error);
      }
    },
    // Método chamado ao selecionar um transportador
    async onSelectTransportador() {
      if (this.selectedTransportador) {
        try {
          await this.fetchTributacoes(this.selectedTransportador.id);
        } catch (error) {
          console.error("Erro ao buscar tributação:", error);
        }
      } else {
        // Se nenhum transportador estiver selecionado, limpar as tributacoes
        this.tributacoes = {
          cfop: {},
          cst: {},
          msgFiscal: {},
          aliquota: {},
          reducao: {},
          completeData: {},
        };
      }
    },
    // Método para buscar tributação
    async fetchTributacoes(idTransportador) {
      try {
        const response = await axios.get(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/?idTransportador=${idTransportador}`
        );
        this.populateTributacoes(response.data.tributacoes);
      } catch (error) {
        console.error("Erro ao buscar dados de tributação:", error);
        this.tributacoes = {
          cfop: {},
          cst: {},
          msgFiscal: {},
          aliquota: {},
          reducao: {},
          completeData: {},
        };
      }
    },
    // Método para popular os dados de tributação
    populateTributacoes(tributacoes) {
      this.tributacoes = {
        cfop: {},
        cst: {},
        msgFiscal: {},
        aliquota: {},
        reducao: {},
        completeData: {},
      };

      tributacoes.forEach((item) => {
        const origem = item.ufRemetente;
        const destino = item.ufDestinatario;

        if (!this.tributacoes.completeData[origem]) {
          this.tributacoes.completeData[origem] = {};
        }

        if (!this.tributacoes.completeData[origem][destino]) {
          this.tributacoes.completeData[origem][destino] = {};
        }

        this.tributacoes.completeData[origem][destino] = { ...item };
        this.tributacoes.cfop[origem] = this.tributacoes.cfop[origem] || {};
        this.tributacoes.cst[origem] = this.tributacoes.cst[origem] || {};
        this.tributacoes.msgFiscal[origem] = this.tributacoes.msgFiscal[origem] || {};
        this.tributacoes.aliquota[origem] = this.tributacoes.aliquota[origem] || {};
        this.tributacoes.reducao[origem] = this.tributacoes.reducao[origem] || {};

        this.tributacoes.cfop[origem][destino] = item.cfop || "-";
        this.tributacoes.cst[origem][destino] = item.cst || "-";
        this.tributacoes.msgFiscal[origem][destino] = item.msgFiscal || "-";
        this.tributacoes.aliquota[origem][destino] = item.aliquota || 0;
        this.tributacoes.reducao[origem][destino] = item.reducao || 0;
      });
    },
    // Método para obter o valor a ser exibido na célula
    getValue(origem, destino) {
      const valor = this.tributacoes[this.selectedTributacao]?.[origem]?.[destino];
      if (this.selectedTributacao === "aliquota" || this.selectedTributacao === "reducao") {
        return this.formatCurrency(valor || 0);
      }
      return valor !== undefined ? valor : "-";
    },
    // Métodos para destacar a célula ao passar o mouse
    setHoverIndices(rowIndex, colIndex) {
      this.hoverRowIndex = rowIndex;
      this.hoverColIndex = colIndex;
    },
    resetHoverIndices() {
      this.hoverRowIndex = null;
      this.hoverColIndex = null;
    },
    // Método para abrir a modal de tributação
    openModal(origem, destino) {
      console.log(`Clicado na célula Origem: ${origem}, Destino: ${destino}`);
      const data = this.tributacoes.completeData?.[origem]?.[destino];

      if (data) {
        console.log("Dados encontrados:", data);
      } else {
        console.warn(`Nenhum dado encontrado para Origem: ${origem}, Destino: ${destino}. Usando valores padrão.`);
      }

      this.modalData = data || {
        ufRemetente: origem,
        ufDestinatario: destino,
        cfop: "",
        cst: "",
        indSn: "",
        msgFiscal: "",
        regime: 0,
        aliquota: 0,
        reducao: 0,
      };
      this.showModal = true;
    },
    // Método para fechar a modal de tributação
    closeModal() {
      this.showModal = false;
      this.modalData = {};
    },
    // Método para salvar os dados da tributação e enviar para a API
    async saveModalData() {
      const {
        ufRemetente,
        ufDestinatario,
        cfop,
        cst,
        indSn,
        msgFiscal,
        regime,
        aliquota,
        reducao,
      } = this.modalData;

      const body = {
        ufTransportador: this.selectedTransportador?.endereco?.uf || "",
        ufRemetente,
        ufDestinatario,
        idClasse: this.selectedTransportador?.idClasse || 0,
        regime: parseInt(regime) || 0, // Assegura que regime é um número inteiro
        cfop,
        cst,
        indSn,
        msgFiscal,
        aliquota: parseFloat(aliquota) || 0,
        reducao: parseFloat(reducao) || 0,
      };

      try {
        const response = await axios.post(
          "https://api.cadastro.workinnovation.com.br/v1/tributacao",
          body
        );
        console.log("Dados salvos com sucesso:", response.data);

        // Atualiza os dados locais após o sucesso do POST
        this.tributacoes.completeData[ufRemetente][ufDestinatario] = { ...this.modalData };
        this.tributacoes.cfop[ufRemetente][ufDestinatario] = cfop;
        this.tributacoes.cst[ufRemetente][ufDestinatario] = cst;
        this.tributacoes.aliquota[ufRemetente][ufDestinatario] = parseFloat(aliquota) || 0;
        this.tributacoes.reducao[ufRemetente][ufDestinatario] = parseFloat(reducao) || 0;
        this.tributacoes.msgFiscal[ufRemetente][ufDestinatario] = msgFiscal;

        this.closeModal();
      } catch (error) {
        console.error("Erro ao salvar os dados:", error);
        alert("Erro ao salvar os dados. Por favor, tente novamente.");
      }
    },
    // Método para formatar números como moeda
    formatCurrency(value) {
      if (isNaN(value)) return "0,00";
      return parseFloat(value).toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    // Métodos para Gerenciamento de Classes

    // Abrir modal para adicionar nova classe
    openAddClassModal() {
      this.newClassDescription = "";
      this.showAddClassModal = true;
    },
    // Fechar modal para adicionar nova classe
    closeAddClassModal() {
      this.showAddClassModal = false;
    },
    // Adicionar nova classe
    async addClass() {
      if (!this.newClassDescription.trim()) {
        alert("Descrição da classe é obrigatória.");
        return;
      }

      const idUsuario = 687; // Substitua por uma variável dinâmica, se necessário

      // Cria os parâmetros como URLSearchParams para enviar como form data
      const params = new URLSearchParams();
      params.append('idUsuario', idUsuario);
      params.append('descricao', this.newClassDescription.trim());

      try {
        const response = await axios.post(
          "https://api.cadastro.workinnovation.com.br/v1/tributacao/classe",
          params.toString(),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );
        console.log("Classe adicionada com sucesso:", response.data);
        this.showAddClassModal = false;
        this.fetchClasses(); // Atualiza a lista de classes
      } catch (error) {
        console.error("Erro ao adicionar classe:", error);
        alert("Erro ao adicionar classe. Por favor, tente novamente.");
      }
    },
    // Abrir modal para editar classe
    openEditClassModal(classe) {
      this.editClassData = { ...classe };
      this.showEditClassModal = true;
    },
    // Fechar modal para editar classe
    closeEditClassModal() {
      this.showEditClassModal = false;
      this.editClassData = {};
    },
    // Atualizar classe
    async updateClass() {
      if (!this.editClassData.descricao.trim()) {
        alert("Descrição da classe é obrigatória.");
        return;
      }

      // Cria os parâmetros como URLSearchParams para enviar como form data
      const params = new URLSearchParams();
      params.append('descricao', this.editClassData.descricao.trim());
      params.append('ativo', this.editClassData.ativo);

      try {
        const response = await axios.put(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/classe/${this.editClassData.id}`,
          params.toString(),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );
        console.log("Classe atualizada com sucesso:", response.data);
        this.showEditClassModal = false;
        this.fetchClasses(); // Atualiza a lista de classes
      } catch (error) {
        console.error("Erro ao atualizar classe:", error);
        alert("Erro ao atualizar classe. Por favor, tente novamente.");
      }
    },
    // Excluir classe
    async deleteClass(classe) {
      if (!confirm(`Tem certeza que deseja excluir a classe "${classe.descricao}"?`)) {
        return;
      }

      try {
        await axios.delete(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/classe/${classe.id}`
        );
        console.log("Classe excluída com sucesso.");
        this.fetchClasses(); // Atualiza a lista de classes
      } catch (error) {
        console.error("Erro ao excluir classe:", error);
        alert("Erro ao excluir classe. Por favor, tente novamente.");
      }
    },
    // Alternar status ativo da classe
    async toggleAtivo(classe) {
      // Cria os parâmetros como URLSearchParams para enviar como form data
      const params = new URLSearchParams();
      params.append('descricao', classe.descricao);
      params.append('ativo', classe.ativo);

      try {
        await axios.put(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/classe/${classe.id}`,
          params.toString(),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        );
        console.log(`Classe "${classe.descricao}" atualizada para ativo: ${classe.ativo}`);
      } catch (error) {
        console.error("Erro ao atualizar status da classe:", error);
        alert("Erro ao atualizar status da classe. Por favor, tente novamente.");
        // Reverter o checkbox em caso de erro
        classe.ativo = !classe.ativo;
      }
    },
    // Método para buscar classes
    async fetchClasses() {
      try {
        const response = await axios.get(
          `https://api.cadastro.workinnovation.com.br/v1/tributacao/classe?idUsuario=687`
        );
        this.classes = response.data;
      } catch (error) {
        console.error("Erro ao buscar classes:", error);
      }
    },
  },
  mounted() {
    this.buscarTransportadores();
    // Inicialmente, se a aba ativa for "gerenciamento", buscar as classes
    if (this.activeTab === "gerenciamento") {
      this.fetchClasses();
    }
  },
  watch: {
    activeTab(newTab) {
      if (newTab === "gerenciamento") {
        this.fetchClasses();
      }
    },
  },
};
</script>

<style scoped>
/* Modal */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

/* Conteúdo da Modal */
.modal-content {
  background: #fff;
  padding: 30px 40px;
  border-radius: 10px;
  width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  animation: fadeIn 0.3s ease-in-out;
}

/* Animação de Entrada */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Botão de Fechar */
.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  transition: color 0.2s;
}

.close-button:hover {
  color: #ff0000;
}

/* Título da Modal */
.modal-title {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
}

/* Formulário da Modal */
.modal-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Grupo de Formulário */
.form-group {
  display: flex;
  flex-direction: column;
}

/* Labels */
.form-label {
  margin-bottom: 8px;
  font-weight: 600;
  color: #555;
}

/* Inputs */
.form-input {
  padding: 10px 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

.form-input:focus {
  border-color: #4a90e2;
  outline: none;
}

/* Checkbox Input */
.form-input-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* Ações do Formulário */
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

/* Botões */
.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-primary {
  background-color: #4a90e2;
  color: #fff;
}

.btn-primary:hover {
  background-color: #357abd;
}

.btn-secondary {
  background-color: #ccc;
  color: #333;
}

.btn-secondary:hover {
  background-color: #b3b3b3;
}

/* Responsividade da Modal */
@media (max-width: 600px) {
  .modal-content {
    width: 90%;
    padding: 20px;
  }

  .modal-title {
    font-size: 1.3rem;
  }

  .btn {
    width: 100%;
    text-align: center;
  }

  .form-actions {
    flex-direction: column;
    align-items: stretch;
  }
}

/* Outros estilos relacionados */

/* Seletores customizados */
.custom-select-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.flag-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.custom-select {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #007bff;
  appearance: none;
  background-color: #f8f9fa;
  font-size: 1rem;
  position: relative;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  pointer-events: none;
  transform: translateY(-50%);
  color: #007bff;
}

/* Barra de Navegação por Abas */
.tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  background-color: #f8f9fa;
  border: 1px;
  border-color: #007bff;
  outline: none;
  transition: background-color 0.3s;
  font-size: 1rem;
  color:black;
}
.tab:hover {
  background-color: #e2e6ea;
  color: black;
}

.tab.active {
  background-color: #ffffff;
  border-bottom: 2px solid #007bff;
  font-weight: bold;
  color: #007bff;
}

/* Cartão do Transportador */
.transportador-card {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.transportador-info {
  display: flex;
  gap: 20px;
  font-size: 0.95rem;
  justify-content: center;
  text-align: center;
}

/* Tabela de Tributação */
.table-container {
  margin-top: 20px;
  overflow-x: auto;
  display: flex;
  justify-content: center;
}

.tributacao-table {
  width: auto;
  table-layout: fixed;
  border-collapse: collapse;
  font-size: 1rem;
}

.tributacao-table th,
.tributacao-table td {
  width: 50px;
  max-width: 50px;
  min-width: 20px;
  padding: 2px;
  border: 1px solid #ddd;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tributacao-table th {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
}

.state-column {
  background-color: #007bff;
  color: #fff;
  font-weight: bold;
  width: 50px;
}

.clickable-cell {
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: white;
}

.pair-cell {
  background-color: #007bff;
  color: white;
}

.clickable-cell:hover {
  background-color: #fbff00;
}

.highlightBorder {
  border: 2px solid red !important;
}

/* Gerenciamento de Classes */
.gerenciamento-container {
  padding: 20px;
}

.add-button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-button:hover {
  background-color: #218838;
}

.classes-list {
  margin-top: 20px;
}

.classes-table {
  width: 100%;
  border-collapse: collapse;
}

.classes-table th,
.classes-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.classes-table th {
  background-color: #007bff;
  color: white;
}

.action-button {
  padding: 8px 12px;
  margin: 0 4px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: white;
  transition: background-color 0.2s;
}

.edit-button {
  background-color: #ffc107;
}

.edit-button:hover {
  background-color: #e0a800;
}

.delete-button {
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #c82333;
}

/* Responsividade Adicional */
@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    padding: 15px;
  }

  .modal-section {
    min-width: 100%;
  }

  .form-group {
    flex-direction: column;
  }

  .button-group,
  .form-actions {
    flex-direction: column;
    gap: 10px;
  }

  .button-group button,
  .form-actions button {
    width: 100%;
  }

  .transportador-info {
    flex-direction: column;
    align-items: center;
  }

  .custom-select-container {
    margin-right: 0;
    margin-bottom: 15px;
  }

  .tributacao-table th,
  .tributacao-table td {
    padding: 4px;
    font-size: 0.8rem;
  }

  .classes-table th,
  .classes-table td {
    padding: 6px;
    font-size: 0.8rem;
  }
}

/* Estilos para Mensagem de Erro */
.error-message {
  color: #dc3545;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}
</style>
